import { format as formatDate } from 'date-fns'
import {
  de as deLocale,
  enUS as enLocale,
  es as esLocale,
  fr as frLocale,
  it as itLocale,
  nl as nlLocale,
  pt as ptLocale,
  sv as svLocale,
} from 'date-fns/locale'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationDE from '../../../../../i18n/de/de.json'
import translationEN from '../../../../../i18n/en/en.json'
import translationES from '../../../../../i18n/es/es.json'
import translationFR from '../../../../../i18n/fr/fr.json'
import translationIT from '../../../../../i18n/it/it.json'
import translationNL from '../../../../../i18n/nl/nl.json'
import translationPT from '../../../../../i18n/pt/pt.json'
import translationSV from '../../../../../i18n/sv/sv.json'

export const DEFAULT_LANGUAGE = 'en'
export const SUPPORTED_LANGUAGES = ['en', 'fr', 'de', 'es', 'pt', 'nl', 'it', 'sv']

const resources = {
  fr: {
    translation: translationFR,
    locale: frLocale,
  },
  en: {
    translation: translationEN,
    locale: enLocale,
  },
  de: {
    translation: translationDE,
    locale: deLocale,
  },
  es: {
    translation: translationES,
    locale: esLocale,
  },
  pt: {
    translation: translationPT,
    locale: ptLocale,
  },
  nl: {
    translation: translationNL,
    locale: nlLocale,
  },
  it: {
    translation: translationIT,
    locale: itLocale,
  },
  sv: {
    translation: translationSV,
    locale: svLocale,
  },
} as const

export type ResourcesKeys = 'fr' | 'en' | 'de' | 'pt' | 'es' | 'nl' | 'it' | 'sv'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // debug: process.env.NODE_ENV === 'development',
    resources,
    supportedLngs: SUPPORTED_LANGUAGES,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format(value, rawFormat, lng) {
        const [format] = (rawFormat || '').split(',').map((v) => v.trim())

        if (format === 'uppercase') {
          return value.toUpperCase()
        }
        if (value instanceof Date) {
          return formatDate(value, format || '', {
            locale: resources[(lng || 'en') as ResourcesKeys].locale,
          })
        }
        if (format === 'price') {
          return Intl.NumberFormat(lng, {
            style: 'currency',
            currency: value.currency || 'EUR',
          }).format(value.price / 100)
        }
        if (format === 'date') {
          return Intl.DateTimeFormat(
            resources[(lng || 'en') as ResourcesKeys].locale.code,
            value.format,
          ).format(value.date)
        }
        return value
      },
    },
    fallbackLng: DEFAULT_LANGUAGE,
  })

export const i18n = i18next

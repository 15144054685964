import { de, enUS, es, fr, it, nl, pt, sv } from 'date-fns/locale'

import { i18n } from '../lib/i18nConfig'

// Map get date-fns Locale object matching i18n.language
export const getDateFnsLocale = (): Locale => {
  switch (i18n.language) {
    case 'en':
      return enUS
    case 'fr':
      return fr
    case 'es':
      return es
    case 'nl':
      return nl
    case 'pt':
      return pt
    case 'it':
      return it
    case 'de':
      return de
    case 'sv':
      return sv
    default:
      return enUS
  }
}

import { useTheme } from '@mui/material'
import { useEffect } from 'react'
import screenfull from 'screenfull'

export const useConvertMUISizeToPx = (size: string): string => {
  const theme = useTheme()
  switch (size) {
    case 'xxl':
      return `${theme.spacing(16)}`
    case 'xl':
      return `${theme.spacing(8)}`
    case 'lg':
      return `${theme.spacing(4)}`
    case 'md':
      return `${theme.spacing(2)}`
    case 'sm':
      return `${theme.spacing(1)}`
    case 'xs':
      return `${theme.spacing(0.5)}`
    default:
      return ''
  }
}

/**
 * Opens the application in fullscreen mode when:
 * - The browser supports fullscreen mode.
 * - The application is not running on a mobile device.
 * - The application is configured to run in fullscreen mode.
 * @param {boolean} isForceFullscreenEnabled - Whether fullscreen mode should be forced.
 * @param {boolean} isMobile - Whether the application is running on a mobile device.
 * @returns {void}
 */
export const openFullscreen = (isForceFullscreenEnabled = false, isMobile: boolean): void => {
  if (screenfull.isEnabled && !isMobile && isForceFullscreenEnabled) {
    // we apply the fullscreen mode to the body element to be able to view Dialogs in fullscreen mode
    screenfull.request(document.getElementsByTagName('body')[0])
  }
}

/**
 * Custom hook that allows leaving fullscreen mode when:
 * - The browser supports fullscreen mode.
 * - The application is configured to run in fullscreen mode.
 * @param isForceFullscreenEnabled - Whether fullscreen mode should be forced.
 */
export const useLeaveFullscreen = (isForceFullscreenEnabled: boolean) => {
  useEffect(() => {
    if (screenfull.isEnabled && isForceFullscreenEnabled && document.fullscreenElement) {
      screenfull.exit()
    }
  }, [isForceFullscreenEnabled])
}

/**
 * Hook that listens for changes in fullscreen mode and calls the provided callback when exiting fullscreen.
 * @param callback The callback function to be called when exiting fullscreen.
 */
export const useFullscreenChange = (callback: () => void) => {
  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.onchange(() => {
        if (!screenfull.isFullscreen) {
          callback()
        }
      })
    }
  }, [callback])
}

import 'katex/dist/katex.min.css'

import { Divider, Link, Typography } from '@mui/material'
import { decode } from 'html-entities'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism } from 'react-syntax-highlighter'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import remarkGfm from 'remark-gfm'
import sanitizeHtml from 'sanitize-html'

import { ZoomableImage } from '../ZoomableImage'
import { atomDark } from './dark'
import { atomLight } from './light'

export type CodingThemeType = 'dark' | 'light'

const getHTMLMappings = (hasZoomableImage: boolean, codingTheme: 'light' | 'dark' = 'dark') => ({
  h1: ({ ...props }) => <Typography variant="h1" {...props} />,
  h2: ({ ...props }) => <Typography variant="h2" {...props} />,
  h3: ({ ...props }) => <Typography variant="h3" {...props} />,
  h4: ({ ...props }) => <Typography variant="h4" {...props} />,
  h5: ({ ...props }) => <Typography variant="h5" {...props} />,
  h6: ({ ...props }) => <Typography variant="h6" {...props} />,
  hr: ({ ...props }) => <Divider sx={{ my: 3 }} {...props} />,
  a: ({ ...props }) => {
    const { href } = props
    return !href.includes('http') ? (
      <Link {...props} />
    ) : (
      <Link target="_blank" rel="nofollow noreferrer noopener" {...props} />
    )
  },
  img: ({ ...props }) => {
    const { src } = props

    return hasZoomableImage ? (
      <ZoomableImage src={src} />
    ) : (
      <img src={src} alt="non zoomable media" style={{ width: 'fit-content', maxWidth: '100%' }} />
    )
  },
  code: ({ ...props }) => {
    return (
      <Prism
        showLineNumbers={true}
        lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
        wrapLines={true}
        language={props.language || 'jsx'}
        style={codingTheme === 'dark' ? atomDark : atomLight}
        lineNumberStyle={{ padding: '0!important', margin: '0!important', marginLeft: 0 }}
        lineNumberContainerStyle={{ padding: '0!important', marginTop: '0!important' }}
        {...props}
      >
        {props.children}
      </Prism>
    )
  },
})

const cleanCodeContent = (codeContent: string) => {
  // Add line return if there is none at the beginning and the end
  const begin = codeContent.startsWith('\n') ? '' : '\n'
  const end = codeContent.endsWith('\n') ? '' : '\n'

  return `\`\`\`${begin}${decode(codeContent)}${end}\`\`\``
}

// This to transform the old code syntax used in our official test to the markdown one
// It also handle code conversion from RichTextEditor
const cleanMarkdownContent = (markdown: string, hasInlineAudioMarkup: boolean) => {
  const cleanedMarkdown = markdown
    // Remove pre tag and add extra lines to make markdown code block works
    .replace(/(<pre[^>]*>)([^]*?)(<\/pre>)/g, (_, _b, rawHtml) => `\n\n${rawHtml}`)
    // Replace code tag with markdown code block
    .replace(/(<code[^>]*>)([^]*?)(<\/code>)/g, (_, _b, rawHtml) => cleanCodeContent(rawHtml))

  if (!hasInlineAudioMarkup) {
    return cleanedMarkdown
  }

  return sanitizeHtml(cleanedMarkdown, {
    allowedTags: [...sanitizeHtml.defaults.allowedTags, 'audio'],
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      audio: ['controls', 'src'],
    },
  })
}

type MarkdownProps = {
  children: string
  codingTheme?: CodingThemeType
  hasZoomableImage?: boolean
}

export const MarkdownComponent = ({
  children,
  codingTheme = 'dark',
  hasZoomableImage = true,
}: MarkdownProps) => {
  const hasInlineAudioMarkup = children.toLowerCase().includes('<audio')

  const cleanContent = cleanMarkdownContent(children, !!hasInlineAudioMarkup)

  if (hasInlineAudioMarkup) {
    return (
      <ReactMarkdown
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rehypePlugins={[rehypeRaw, rehypeKatex]}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        remarkPlugins={[remarkGfm]}
        components={getHTMLMappings(hasZoomableImage, codingTheme)}
      >
        {cleanContent}
      </ReactMarkdown>
    )
  }

  return (
    <ReactMarkdown
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rehypePlugins={[rehypeRaw, rehypeKatex, rehypeSanitize]}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      remarkPlugins={[remarkGfm]}
      components={getHTMLMappings(hasZoomableImage, codingTheme)}
    >
      {cleanContent}
    </ReactMarkdown>
  )
}

export const Markdown = React.memo(MarkdownComponent)

import { deDE, enUS, esES, frFR, itIT, nlNL, ptPT } from '@mui/material/locale'
import {
  deDE as dataGridDE,
  enUS as dataGridEN,
  esES as dataGridES,
  frFR as dataGridFR,
  itIT as dataGridIT,
  nlNL as dataGridNL,
  ptBR as dataGridPT,
} from '@mui/x-data-grid-premium'
import {
  deDE as datePickersDE,
  enUS as datePickersEN,
  esES as datePickersES,
  frFR as datePickersFR,
  itIT as datePickersIT,
  nlNL as datePickersNL,
  ptBR as datePickersPT,
} from '@mui/x-date-pickers'

export const MUI_LOCALES = {
  fr: frFR,
  it: itIT,
  pt: ptPT,
  nl: nlNL,
  de: deDE,
  es: esES,
  en: enUS,
}

export const DATEPICKERS_LOCALES = {
  fr: datePickersFR,
  it: datePickersIT,
  pt: datePickersPT,
  nl: datePickersNL,
  de: datePickersDE,
  es: datePickersES,
  en: datePickersEN,
}

export const DATAGRID_LOCALES = {
  fr: dataGridFR,
  it: dataGridIT,
  pt: dataGridPT,
  nl: dataGridNL,
  de: dataGridDE,
  es: dataGridES,
  en: dataGridEN,
}

import { de, enGB, es, fr, it, nl, pt, sv } from 'date-fns/locale'

export const getCurrentLocale = (language: string) => {
  switch (language) {
    case 'fr':
      return fr
    case 'it':
      return it
    case 'pt':
      return pt
    case 'nl':
      return nl
    case 'de':
      return de
    case 'es':
      return es
    case 'sv':
      return sv
    default:
      return enGB
  }
}

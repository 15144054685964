import { Theme } from '@mui/material'
import { TFunction } from 'i18next'

import { Book, Link, MessagesWord, Target } from '../../../assets/src/lib/icons'

export enum FlatsDimensionEnum {
  GRAMMATICAL_RANGE_AND_ACCURACY = 'grammatical_range_and_accuracy',
  TASK_ACHIEVEMENT = 'task_achievement',
  COHERENCE_AND_COHESION = 'coherence_and_cohesion',
  LEXICAL_RESOURCE = 'lexical_resource',
}

export const getDimensionLabel = (t: TFunction<'translation'>, dimension: string) => {
  switch (dimension) {
    case FlatsDimensionEnum.GRAMMATICAL_RANGE_AND_ACCURACY:
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.grammatical_range_and_accuracy',
        'Grammatical versatility',
      )
    case FlatsDimensionEnum.TASK_ACHIEVEMENT:
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.task_achievement',
        'Task achievement',
      )
    case FlatsDimensionEnum.COHERENCE_AND_COHESION:
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.coherence_and_cohesion',
        'Logical connectivity',
      )
    case FlatsDimensionEnum.LEXICAL_RESOURCE:
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.lexical_resource',
        'Lexical depth',
      )
    default:
      return dimension
  }
}

export const getDimensionIcon = (theme: Theme, dimension: string) => {
  switch (dimension) {
    case FlatsDimensionEnum.GRAMMATICAL_RANGE_AND_ACCURACY:
      return <MessagesWord stroke={theme.palette.text.secondary} />
    case FlatsDimensionEnum.TASK_ACHIEVEMENT:
      return <Target stroke={theme.palette.text.secondary} />
    case FlatsDimensionEnum.COHERENCE_AND_COHESION:
      return <Link stroke={theme.palette.text.secondary} />
    case FlatsDimensionEnum.LEXICAL_RESOURCE:
      return <Book stroke={theme.palette.text.secondary} />
    default:
      return null
  }
}

// Check this documentation to check which language need a transformation
// https://www.tiny.cloud/docs/configure/localization/#usingtheenterpriselanguagepacks
import { i18n } from './i18nConfig'

export const getCurrentRichTextEditorLanguage = () => {
  switch (i18n.language) {
    case 'fr':
      return 'fr_FR'
    case 'pt':
      return 'pt-PT'
    case 'sv':
      return 'sv_SE'
    default:
      return i18n.language
  }
}

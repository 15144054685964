import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const MessagesWord: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9769 20.9028C14.4247 20.9113 16.8753 19.8837 18.621 17.8126C21.2332 14.7139 21.3154 10.0639 18.8069 6.88096C15.5412 2.73594 9.50942 2.47646 5.8965 6.10161C3.25543 8.75068 2.68501 12.6755 4.16473 15.8858C4.28796 16.1939 4.76502 17.0407 5.08523 17.5981C5.26728 17.9147 5.24861 18.3054 5.03855 18.6031C4.82756 18.901 4.56057 19.2823 4.35238 19.5952C3.97988 20.1544 4.37572 20.9038 5.04602 20.9028L5.56571 20.9026C7.84503 20.9017 11.1827 20.9002 11.9769 20.9028Z"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2284 13.1346H13.7685M14.7537 15.3969L11.9988 9.06934L9.24377 15.3969"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
